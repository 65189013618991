.l-nav-desktop {
  height: 100vh;
  position: fixed;
}

@media (max-width: 991px) {
  .l-nav-desktop {
    display: none;
  }
}

.nav-banner-container {
  height: 100vh;
}

@media (max-width: 576px) {
  .nav-banner-container {
    height: 100vh;
  }
  .nav-banner {
    height: 200px;
  }
}

.nav-header {
  position: fixed;
}

@media (min-width: 992px) {
  .nav-header {
    display: none;
  }
}

.nav-container {
  position: relative;
}

.nav-header {
  position: relative;
  z-index: 10; /* Higher z-index to stay above the menu */
  padding: 20px;
}

.nav-button {
  position: absolute;
  right: 15px;
  top: 20px;
  overflow: visible;
  color: var(--white);

  cursor: pointer;
}

.nav-button img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.close-icon {
  display: none;
}

/* Hidden by default */
.nav-menu {
  display: none;
  position: absolute;
  height: 100vh;
  top: 100%; /* Position it below .test5 */
  left: 0;
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  z-index: 5; /* Lower z-index to be behind .test5 */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.l-nav-desktop {
  background-color: var(--black);
}

.nav-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-menu ul li {
  margin: 5px 0;
}

.nav-menu ul li a {
  text-decoration: none;
}

/* Show the menu when active */
.nav-menu.active {
  display: block;
}

.nav-hover {
  text-decoration: none;
  transition: text-decoration 0.3s;
}

.nav-hover:hover {
  text-decoration: underline;
}

.nav-hover.active {
  text-decoration: underline;
  font-weight: bold;
}

.nav {
  background-color: var(--black);
  color: var(--white);
}

.logo {
  width: 300px;
}

@media (max-width: 1325px) {
  .logo {
    width: 200px;
  }
}

@media (max-width: 991px) {
  .logo {
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .logo {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.nav-banner {
  background-color: red;
  column-rule: blue;
  height: 20%;
  position: relative;
}

.nav-banner img {
  position: absolute;
  max-height: 100%;
  width: auto;
  left: 10px;
  padding: 10px 5px 10px 5px;
}

// align-content: flex-start

@media (max-width: 576px) {
  .nav-banner-container {
    align-content: flex-start !important;
  }
  .nav-banner {
    height: 170px;
  }
}

.nav-gd {
  background-color: var(--cover1);
}

.nav-ui {
  background-color: var(--cover2);
}
.nav-md {
  background-color: var(--cover1);
}
.nav-bs {
  background-color: var(--cover3);
}
.nav-about {
  background-color: var(--cover4);
}
