.footer-container {
  position: absolute !important;
  bottom: 20px !important;
  color: var(--white);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -1px;
}

@media (max-width: 991px) {
  .footer-container {
    position: relative !important;
    top: 20%;
  }
}

.footer-mail {
  margin-bottom: 20px;
}

.footer-contact {
  margin-bottom: 20px;
}

.footer-container ul {
  color: var(--white);
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
}

.footer-title {
  font-family: "gatwick-regular ";
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 21px;
}

.footer-disclamer {
  color: var(--violet);
  font-size: 12px;
}

.footer-button {
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: var(--white);
  width: fit-content;
  transition: 0.2s ease-in-out;
}

.footer-button:hover {
  background-color: var(--yellow);
}

.footer-button a {
  color: var(--black) !important;
  text-decoration: none;
}

.nav-menu .footer-socials a {
  text-decoration: underline;
  color: var(--white);
  text-transform: uppercase;
  font-size: 14px !important;
  line-height: -10px !important;
  letter-spacing: -1px !important;
}

.footer-socials a {
  text-decoration: underline;
  color: var(--white);
  text-transform: uppercase;
  font-size: 14px !important;
  line-height: 14px !important;
  letter-spacing: -1px !important;
}
