.portfolio-container {
  background-color: var(--black);
  color: var(--white);
}

.portfolio-cover {
  height: 150px;
}

.portfolio-article {
  padding-top: 15px;
}

.portfolio-section {
  padding-bottom: 15px;
}

.portfolio-txt h1 {
  font-family: "fraktion-bold";
  font-size: 25px;
  line-height: 20px;
}

.portfolio-txt h2 {
  font-family: "fraktion-lightitalic";
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: -5px;
}

.portfolio-txt p {
  font-family: "fraktion-regular";
  font-size: 15px;
  letter-spacing: -1px;
  line-height: 18px;
  margin-right: 40px;
  max-width: 400px;
}

@media (max-width: 991px) {
  .portfolio-txt {
    padding-top: 15px;
  }
  .portfolio-txt p {
    max-width: 600px;
    font-size: 18px;
    line-height: 23px;
  }
}

// hidden

.hidden-text {
  display: block;
}

.toggle-text {
  display: none;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 992px) {
  .hidden-text {
    display: none;
  }

  .toggle-text {
    display: inline-block;
  }

  .hidden-text.active {
    display: block;
  }
}
