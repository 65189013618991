:root {
  --black: #121212;
  --white: #fdfdfd;
  --violet: #bd90ff;
  --yellow: #bdff00;
  --cover1: #303030;
  --cover2: #5df394;
  --cover3: #bdff00;
  --cover4: #bd90ff;
  --cover5: #f2f8ff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "fraktion-regular";
  text-transform: uppercase;
  background-color: var(--black);
}

p {
  text-transform: none;
}

ul {
  list-style-type: none;
  line-height: 22px;
  font-size: 17px;
}

img,
video {
  display: block;
}

a {
  color: var(--white);
}

a:visited {
  color: var(--white) !important;
}

.header {
  height: 100vh;
}
