@font-face {
  font-family: "fraktion-regular";
  src: url("../fonts/PPFraktionMono-Regular.woff") format("woff"),
    url("../fonts/PPFraktionMono-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fraktion-lightitalic";
  src: url("../fonts/PPFraktionMono-LightItalic.woff") format("woff"),
    url("../fonts/PPFraktionMono-LightItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fraktion-bold";
  src: url("../fonts/PPFraktionMono-Bold.woff") format("woff"),
    url("../fonts/PPFraktionMono-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gatwick-regular";
  src: url("../fonts/PPGatwick-Regular.woff") format("woff"),
    url("../fonts/PPGatwick-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
