.thumbnail {
  width: 100%;
  cursor: pointer;
}

.lightbox {
  display: none; /* Hide lightboxes by default */
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.lightbox-content {
  margin: auto;
  display: block;
  max-width: 80%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 20px;
  right: 25px;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
  font-family: "fraktion-regular";
}

.close:hover,
.close:focus {
  color: #bbb;
}

@media (max-width: 992px) {
  .thumbnail {
    cursor: default;
  }
  .lightbox {
    display: none !important;
  }
}
